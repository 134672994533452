const WebsiteSDK = window.WebsiteSDK.default;

/**
 * Created by Eyal Gursoy
 * Company: Mayple
 *
 */
(function () {
  const FORM_TAG = "form";

  const websiteSDK = new WebsiteSDK({
    formId: "9c266462-4b62-4255-bd93-7bbf92103f72",
  });

  function validateFormData(filteredFormData) {
    console.log(filteredFormData);
    return true;
  }

  function handleFormSubmit(event) {
    // stop default behavior for now
    event.preventDefault();

    // get the form from event.target
    const form = event.target;

    // get FormData Object form the form
    const formData = new FormData(form);

    // Creates a Lead from the form data on submit
    const filteredFormData = getFilteredFormData(formData);

    if (!validateFormData(filteredFormData)) {
      return;
    }

    // Creates a Lead from the form data on submit
    const lead = getLeadFromFormData(filteredFormData);

    lead.frontendSalesQualificationScore = websiteSDK.calcSalesQualificationLeadScore(lead);

    // Submit the data to Hubspot
    websiteSDK.submitHubspotForm(lead).then((result) => {
      console.log(result);
    });

    websiteSDK.createProjectLead(lead);

    // Continue with the normal page behavior
    // form.submit(event);
  }

  function isArrayKey(key) {
    return key.endsWith("[]");
  }

  function getArrayKey(key) {
    return key.replace("[]", "");
  }

  function getFilteredFormData(formData) {
    const filteredFormData = {};
    for (const pair of formData.entries()) {
      const [key, value] = pair;
      if (isArrayKey(key)) {
        const arrayKey = getArrayKey(key);
        filteredFormData[arrayKey] = filteredFormData[arrayKey] || [];
        filteredFormData[arrayKey].push(value);
      } else {
        filteredFormData[key] = value;
      }
    }
    return filteredFormData;
  }

  function getEstimatedMediaBudget(value) {
    const mapValues = {
      "1000 - 5,000": 3000,
      "5,000 - 10,000": 7500,
      "10,000 - 30,000": 20000,
      "30,000 - 50,000": 40000,
      "50,000 - 100,000": 75000,
      "100,000 +": 100001,
    };

    return mapValues[value] ? mapValues[value] : 0;
  }

  function getLeadFromFormData(formData) {
    return {
      firstName: formData["form_fields[firstName]"],
      emailAddress: formData["form_fields[emailAddress]"],
      phoneNumber: formData["form_fields[phoneNumber]"],
      websiteAddress: formData["form_fields[websiteAddress]"],
      estimatedMediaBudget: getEstimatedMediaBudget(
        formData["form_fields[field_spend]"]
      ),
      industry: [],
      hubspotFormProps: {
        utm_source: formData["form_fields[utm_source]"],
        utm_medium: formData["form_fields[utm_medium]"],
        utm_content: formData["form_fields[utm_content]"],
        utm_campaign: formData["form_fields[utm_campaign]"],
        utm_term: formData["form_fields[utm_term]"],
        hs_persona: "persona_1",
      },
      /* defaults */
      serviceTypes: ['OTHER'],
      state: 'NONE',
      targetKPI: 'ROAS',
      mainGoal: 'IMPROVE_EXISTING_CAMPAIGNS',
      locations: 'US',
      preferMarketerInTargetedLocations: true,
      targetKPIValue: 0,
    };
  }

  function connectEventListener(form) {
    form.addEventListener("submit", handleFormSubmit);
  }

  function connectForms() {
    // Look for all the forms in the page
    const pageForms = document.getElementsByTagName(FORM_TAG);
    const formsList = Array.prototype.slice.call(pageForms);

    if (!formsList.length) {
      console.log("could not find any form in the page");
      return;
    }

    // Connect an event listener to each form submit
    formsList.forEach(connectEventListener);
  }

  function handleFormChanges() {
    // Handle changes to call type
    const callTypeInput = document.querySelector("#form-field-call_type");
    document
      .querySelector("#form-field-field_spend")
      .addEventListener("change", function (event) {
        const callType =
          event.target.value === "1000 - 5,000" ? "sdr" : "sales";
        callTypeInput.value = callType;
      });
  }

  window.addEventListener("load", connectForms);
  window.addEventListener("load", handleFormChanges);
})();
